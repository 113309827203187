@font-face {
  font-family: "Robotobold";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Roboto-Regular_0.ttf");
}

@font-face {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Poppins-Medium.ttf");
}
.heroImage {
  background-position: left !important;
}
.banner {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 90px;
}
.subscribe_wrapper {
  position: relative;
  margin: -16.5rem 0.5rem 7rem 0.5rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 10;
}
.subscribe_wrapper * {
  font-size: 22px !important;
}
.subscribe_sign_box {
  visibility: hidden;
  opacity: 0;
  transform: translateY(2rem);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #083f88;
  border-radius: 20px;
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0.25rem 0;
  transition: 0.4s all;
}

.subscribe_sign_box.active {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.form-control[type="submit"] {
  max-width: 22rem;
  margin-top: 22px;
}
.subscribe_button {
  cursor: pointer;
  background: #083f88;
  border-radius: 6.91911px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  outline: 0;
  border-width: 0;
  padding: 0.25rem 2rem;
  margin-top: 1.25rem;
  transition: 0.4s all;
}
.subscribe_button:hover {
  background-color: #26a9e0;
}
.subscribe_input {
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
  box-sizing: border-box;
  border: 0.695467px solid #083f88;
  border-radius: 6.95467px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #b0b0b0;
}
.subscribe_input::placeholder {
  color: #b0b0b0;
}
.subscribe_box {
  cursor: pointer;
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1.5px solid #083f88;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.4s background;
  border-radius: 20px;
}
.subscribe_text {
  box-sizing: border-box;
  border-radius: 20px;
  margin: 0;
  padding: 0.25rem 0.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  width: 100%;
  color: #083f88;
  transition: 0.4s color;
}

.subscribe_box:hover,
.subscribe_text:hover {
  background-color: #083f88;
  color: #ffffff;
}
.quoteWrapper {
  color: #fff;
  font-family: "RobotoRegular";
  font-size: 20px;
  background: url("https://d2b7dijo04ypct.cloudfront.net/shine/Quote-patch.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.videosWrapper h4 {
  color: #005495;
  font-family: "RobotoMedium";
}
.gallery-item {
  padding: 0.5rem;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  padding: 5px;
  border: 5px solid #005495;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 250px !important;
}

.aboutContent {
  width: 80%;
}

.aboutTitle {
  color: #005495;
  font-size: 40px;
  font-family: "RobotoMedium";
  padding-right: 20px;
}

.aboutContent p {
  color: #000;
  font-size: 18px;
  font-family: "RobotoRegular";
}

.mainBlue {
  color: #005495;
}
.talent{
  color: #005495;
}
footer a {
  color: #26a9e0;
}
.footertext a:hover {
  color: white!important;
}
.valid {
  position: absolute;
  width: fit-content;
  margin-top: -0.15rem;
  font-size: 16px !important;
}
@media (max-width: 1999px) {
  .subscribe_wrapper {
    margin: -16.5rem 0.5rem 6rem 0.5rem;
  }
}
@media only screen and (max-width: 1699.91px) {
  .subscribe_wrapper {
    margin: -15.75rem 0.5rem 5rem 0.5rem;
  }
  .aboutTitle {
    font-size: 32px;
  }
  .quoteWrapper {
    background: url("https://d2b7dijo04ypct.cloudfront.net/shine/Quote-patch-lg.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-width: 1299.91px) {
  .subscribe_wrapper {
    margin: -14.75rem 0.5rem 4rem 0.5rem;
  }

  .aboutContent {
    width: 90%;
  }

  .aboutTitle {
    font-size: 26px;
  }

  .quoteWrapper {
    background: url("https://d2b7dijo04ypct.cloudfront.net/shine/Quote-patch-md.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media only screen and (max-width: 1199px) {
  .subscribe_text {
    font-size: 20px;
  }
  .subscribe_wrapper {
    margin: -14.5rem 0.5rem 3rem 0.5rem;
  }
}
@media only screen and (max-width: 991px) {
  .quoteWrapper {
    background: url("https://d2b7dijo04ypct.cloudfront.net/shine/blue-patch.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .subscribe_wrapper {
    margin: -13rem 0.25rem 2rem 0.25rem;
  }
  .subscribe_wrapper * {
    font-size: 18px !important;
  }
  .valid {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe_wrapper {
    margin: -11.75rem 0.25rem 2rem 0.5rem;
  }
  .subscribe_wrapper * {
    font-size: 16px !important;
  }
  .valid {
    font-size: 14px !important;
  }
  .contact-section .contact-form {
    padding: 2rem;
    margin: 2rem 0rem;
    border: none;
  }
  .contact-form .clrd-btn {
    border-radius: 12px;
    font-size: 36px;
  }

  .quoteWrapper {
    font-size: 18px;
  }

  .quoteWrapper h4 {
    font-size: 1.25rem;
  }
}
@media (max-width:699px){
  .subscribe_wrapper {
    margin: -12.2rem 0.25rem 2rem 0.5rem;
  }
}
@media only screen and (min-width: 700px)  and (max-width: 767px)  {
  .subscribe_wrapper {
    margin-top: -12.2rem;
  }
 }
@media (max-width: 640px) {
  .subscribe_wrapper {
    margin: -7rem auto -1rem auto;
  }

  .subscribe_text {
    padding: 0.25rem;
  }
  .subscribe_sign_box {
    padding: 0.5rem;
    margin-bottom: 0.15rem;
  }
}
@media only screen and (min-width: 2560px) {
  .subscribe_sign_box{
    margin-top: -1.5rem;
  }
.creating1{
  margin-top: -5.8rem!important;
}
}
@media only screen and (min-width: 641px)  and (max-width: 759px)  {
 .banner{
   height: 240px!important;
 }
}
@media only screen and (min-width: 760px)  and (max-width: 991px)  {
  .banner{
    height: 260px!important;
    padding-top: 80px;
  }
 }
@media only screen and (min-width: 2100px)  and (max-width: 2560px)  {
  .subscribe_sign_box{
    margin-top: -1rem;
  }
.creating1{
  margin-top: -5.1rem!important;
}
}
@media only screen and (max-width: 499.91px) {

  .valid {
    font-size: 14px !important;
  }
  .contact-section .contact-form {
    padding: 1.25rem;
    margin: 2rem 0rem;
  }

  .contact-section h4 {
    font-size: 36px;
  }

  .contact-section .contact-form .tcpa {
    font-size: 12px;
    line-height: 15px;
  }

  .contact-form .clrd-btn {
    font-size: 24px;
    padding: 0.25rem 3.5rem;
  }
}

@media only screen and (max-width: 640px) {
  .subscribe_wrapper {
    flex-direction: column-reverse;
    margin: 0.5rem auto -8.5rem auto;
  }

  .contact-section h4 {
    font-size: 32px;
  }
  .contact-form .clrd-btn {
    font-size: 20px;
    padding: 0.25rem 2.5rem;
  }
}
.navbar{
  background: #183484 !important;
    z-index: 999;
    box-shadow:none;
    padding-right: 1em;
    padding-top: 1em;
    height: 100px;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    justify-content: center !important;
}
.navbar.active{ 
  background:#183484!important;

}
@media screen and (min-width:992px) and (max-width:992.9px){
.logo-{
  width:230px !important;
  margin-right: 36rem; 
}
.navbar{
  height: 100px;
}
}
@media screen and (min-width:993px) and (max-width:1100px){
  .banner{
    height: 300px;
    padding-top: 88px;
  }
  }
@media screen and (min-width:993px) and (max-width:1100px){
  .logo-{
    width:230px !important;
    margin-right: 4rem; 
     
  }
  .navbar{
    height: 90px;
  }
  }
  @media screen and (max-width:768px){
    .topStrip{
      padding-bottom: 4rem;
    }
  }

@media screen and (min-width:1100px) and (max-width:1200px){
  .logo-{
    width:230px !important;
    margin-right: 9rem; 
    height: 55px;
  }
  .navbar{
    height: 95px;
  }
    }

@media screen and (min-width:1200px) and (max-width:1300px){
  .logo-{
    width:240px !important;
    margin-right: 1rem; 
    height: 60px;
  }
  .navbar{
    height: 100px;
  }
    }
@media screen and (min-width:1300px) and (max-width:1360px){
  .logo-{
    width:240px !important;
    margin-right: 7rem; 
    height: 60px;
  }
  .navbar{
    height: 110px;
  }
    }
@media screen and (min-width:1360px) and (max-width:1400px){
  .logo-{
    width:240px !important;
    margin-right: 11rem; 
    height: 60px;
  }
  .navbar{
    height: 110px;
  }
    }
@media screen and (min-width:1400px) and (max-width:1500px){
  .logo-{
    width:260px !important;
    margin-right: 7rem; 
    height: 60px;
  }
  .navbar{
    height: 14px;
  }
    }
@media screen and (min-width:1500px) and (max-width:1600px){
  .logo-{
    width:280px !important;
    margin-right: 10rem; 
    height: 70px;
  }
  .navbar{
    height: 120px;
  }
    }
@media screen and (min-width:1600px) and (max-width:1670px){
  .logo-{
    width:280px !important;
    margin-right: 13rem; 
    height: 70px;
  }
  .navbar{
    height: 125px;
  }
    }
  @media screen and (min-width:1670px) and (max-width:1700px){
    .logo-{
      width:280px !important;
      margin-right: 18rem; 
      height: 70px;
    }
    .navbar{
      height: 125px;
    }
      }
@media screen and (min-width:1700px) and (max-width:1800px){
  .logo-{
    width:320px !important;
    margin-right: 19rem; 
    height: 75px;
  }
  .navbar{
    height: 130px;
  }
    }
@media screen and (min-width:1800px) and (max-width:1900px){
  .logo-{
    width:320px !important;
    margin-right: 23rem; 
    height: 75px;
  }
  .navbar{
    height: 140px;
  }
    }
@media screen and (min-width:1900px)and (max-width:2000px){
  .logo-{
    width:360px !important;
    margin-right: 26rem; 
    height: 85px;
  }
  .navbar{
    height: 150px;
  }
    }
@media screen and (min-width:2000px)and (max-width:2100px){
  .logo-{
    width:360px !important;
    margin-right: 30rem; 
    height: 85px;
  }
  .navbar{
    height: 150px;
  }
    }
@media screen and (min-width:2100px)and (max-width:2200px){
  .logo-{
    width:360px !important;
    margin-right: 34rem; 
    height: 85px;
  }
  .navbar{
    height: 160px;
  }
    }
@media screen and (min-width:2200px)and (max-width:2300px){
  .logo-{
    width:360px !important;
    margin-right: 39rem; 
    height: 85px;
  }
  .navbar{
    height: 160px;
  }
    }
@media screen and (min-width:2300px){
  .logo-{
    width:380px !important;
    margin-right: 45rem; 
    height: 100px;
  }
  .navbar{
    height: 180px;
  }
    }
@media screen and (min-width:2500px){
  .logo-{
    width:380px !important;
    margin-right: 57rem; 
    height: 100px;
  }
  .navbar{
    height: 180px;
  }
    }
  
  @media screen and (min-width:2560px){
    .logo-{
      width:380px !important;
      margin-right: 55rem; 
      height: 100px;
    }
    .navbar{
      height: 190px;
    }
      }
  @media screen and (min-width:2650px){
    .logo-{
      width:380px !important;
      margin-right: 60rem; 
      height: 100px;
    }
    .navbar{
      height: 220px;
    }
      }
@media screen and (min-width:2700px){
  .logo-{
    width:380px !important;
    margin-right: 65rem; 
    height: 100px;
  }
  .navbar{
    height: 235px;
  }
    }
@media screen and (min-width:2900px){
  .logo-{
    width:380px !important;
    margin-right: 73rem; 
    height: 100px;
  }
  .navbar{
    height: 235px;
  }
    }
@media screen and (min-width:1350px) and (max-width:1500px){
  
  .navbar{
    height: 110px;
  }
    }
@media(max-width:991.9px){
  .navbar{
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      background: #083f88 !important;
  z-index: 15;

  }
  .navbar.active,
  .navbar-collapse{
    background: linear-gradient(0deg, rgba(8, 63, 136, 0.8), rgba(8, 63, 136, 0.8))
  }
}
@media screen and (min-width:1400px) {
.navbar{
    padding-right: 3em;
    padding-top: 1.5em;
    padding-bottom:1rem;
}
}
@media only screen and (min-width: 320px) and (max-width: 350px){
  .logo- {
    width: 150px;
    height: auto;
    position: relative;
    /* left: -6rem; */
    top: -1rem;
}
.navbar{
  height: 48px
}
  }
@media only screen and (min-width: 350px) and (max-width: 399.91px){
  .logo- {
    width: 150px;
    height: auto;
    position: relative;
    top: -0.8rem;
}
.navbar{
  height: 55px
}
  }

@media only screen and (min-width: 400px) and (max-width: 524px){
  .logo- {
    width: 150px;
    height: auto;
    position: relative;
    /* left: -7rem; */
    top: -13px;
}
.navbar{
  height: 60px
}
  }
  @media only screen and (min-width: 425px) and (max-width: 500px){
    .logo- {
      width: 150px;
      height: auto;
      position: relative;
      top: -13px;
  }
  .navbar{
    height: 60px
  }
    }
@media only screen and (min-width: 460px) and (max-width: 500px){
  .logo- {
    width: 150px;
    height: auto;
    position: relative;
    /* left: -8.6rem; */
    top: -11px;
}
.navbar{
  height: 80px
}
  }

@media only screen and (min-width: 500px) and (max-width: 550px){
  .logo- {
    width: 150px;
    height: 2.5rem;
    position: relative;
    left: -9.5rem;
    top: -10px;
}
.navbar{
  height: 78px
}
  }

  @media only screen and (min-width: 550px) and (max-width: 600px){
    .logo- {
      width: 150px;
      height: 2.5rem;
      position: relative;
      left: -10rem;
      top: -8px;
  }
  .navbar{
    height: 80px
  }
    }

@media only screen and (min-width: 600px) and (max-width: 640px){
  .logo- {
    width: 160px;
    height: 2.5rem;
    position: relative;
    left: -11.5rem;
    top: -6px;
}
.navbar{
  height: 80px
}
  }
  @media only screen and (min-width: 640px) and (max-width: 700px){
    .logo- {
      width: 160px;
      height: 2.5rem;
      position: relative;
      left: -12.5rem;
      top: -6px;
  }
  .navbar{
    height: 80px
  }
    }
@media only screen and (min-width: 700px) and (max-width: 760px){
  .logo- {
    width: 160px;
    height: 2.5rem;
    position: relative;
    left: -13.5rem;
    top: -5px;
}
.navbar{
  height: 80px
}
  }

@media only screen and (min-width: 760px) and (max-width: 850px){
  .logo- {
    width: 170px;
    height: 2.5rem;
    position: relative;
    top: -4px;
}
.navbar{
  height: 85px
}
  }

@media only screen and (min-width: 850px) and (max-width: 991.9px){
  .logo- {
    width: 190px;
    height: 3rem;
    position: relative;
    left: -17.5rem;
    top: -2px;
}
.navbar{
  height: 85px
}
  }

.topStrip{
background-color: #083f88 ;
color: white;
}
.topStrip > a{
  text-decoration: underline;
}
.mobiletextmove > p {
  text-align: center !important;
}
.mobiletextmove > p {
  text-align: center !important;
}
/* @media screen and (max-width: 992px) {
  .subscribe_wrapper { 
    z-index: 0!important;
  }
} */
@media screen and (min-width: 992px) {
  .EOE-logo {
    margin-left: 2.5rem !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 350px) {
  .EOH-logo {
    margin-left: -1rem !important;
  }
  .mobiletextmove > p {
    font-size:0.8rem !important;
    font-family: "RobotoRegular";
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -26%!important;
    margin-left: 4.8%!important;
    line-height: 0.5rem;
  }
  .creating{
    font-size: 150%;
    font-weight: 640;
    line-height: 12px;
  }
  .banner{
    width: 370px;
    height: auto;
    padding-top: 47px;
  }
  .creating1{
      right: 1%;
      color: white;
      position: absolute;
      margin-top: -2.7rem;
      font-size: 7.5px;
      
  }
}
@media screen and (min-width: 350px) and (max-width: 375px) {
  .EOH-logo {
    margin-left: -1rem !important;
  }
  .mobiletextmove > p {
    font-size:0.8rem !important;
    font-family: "RobotoRegular";
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -23%!important;
    margin-left: 5.5%!important;
    line-height: 0.5rem;
  }
  .creating{
    font-size: 150%;
    font-weight: 640;
    line-height: 12px;
  }
  .banner{
    width: 370px;
    height: auto;
    padding-top: 52px;
  }
  .creating1{
      right: 1%;
      color: white;
      position: absolute;
      margin-top: -2.6rem;
      font-size: 7.2px;
  }
}
@media screen and (min-width: 375px) and (max-width: 424.9px) {
  .footertext{
    font-size: 0.9rem!important;
    font-family: "RobotoRegular";
}
.banner{
  padding-top: 55px;
}
.creating{
  font-size: 180%;
  font-weight: 640;
 line-height: 14px;
}
.creatingd{
  font-size: 9px!important;
  position: absolute;
  margin-top: -22.5%!important;
  margin-left: 8.8%!important;
}
.creating1{
  right: 1%;
  color: white;
  position: absolute;
  margin-top: -2.7rem;
  font-size: 7.3px;
}
}
@media screen and (min-width: 425px) and (max-width: 440px) {
  .footertext{
    font-size: 1.1rem!important;
    font-family: "RobotoRegular";
}
.banner{
  padding-top: 55px;
}
.creating{
  font-size: 210%;
  font-weight: 640;
  line-height: 16px;
}
.creatingd{
  font-size: 9px!important;
  position: absolute;
  margin-top: -21.5%!important;
  margin-left: 7%!important;
}
.creating1{
  right: 1%;
  color: white;
  position: absolute;
  margin-top: -2.7rem;
  font-size: 8.4px;
}
}
@media screen and (min-width: 440px) and (max-width: 991px) {
  .footertext{
    font-size: 18px!important;
    font-family: "RobotoRegular";
    font-weight: 500;
}
.creating1{
  right: 2%;
  color: white;
  position: absolute;
  margin-top: -2.7rem;
  font-size: 8.6px;
}
.creatingd{
  font-size: 9px!important;
  position: absolute;
  margin-top: -21.5%!important;
  margin-left: 6%!important;
}
}
@media screen and (min-width: 992px) and (max-width: 1024px) {
  
.creating1{
  right: 2%;
  color: white;
  position: absolute;
  margin-top: -2.2rem;
  font-size: 120%;
}
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .creating{
    font-size: 510%;
    font-weight: 500!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -17%!important;
    margin-left: 8.8%!important;
  }
.creating1{
  right: 2%;
  color: white;
  position: absolute;
  margin-top: -2.3rem;
  font-size: 125%;
}
}
@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -2.5rem;
    font-size: 133%;
  }
}
@media screen and (min-width: 2500px){
  .creating{
    font-size: 810%;
    font-weight: 500!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -18%!important;
    margin-left: 8.8%!important;
  }
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -7.4rem;
    font-size: 300%;
  }
}
@media screen and (min-width: 2200px) and (max-width: 2500px) {
  .creating{
    font-size: 810%;
    font-weight: 500!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -18%!important;
    margin-left: 8.8%!important;
  }
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -6.5rem;
    font-size: 270%;
  }
}
@media screen and (min-width: 2000px) and (max-width: 2200px) {
  .creating{
    font-size: 810%;
    font-weight: 500!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -18%!important;
    margin-left: 8.8%!important;
  }
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -6.5rem;
    font-size: 240%;
  }
}
@media screen and (min-width: 1800px) and (max-width: 2000px)  {
  .creating{
    font-size: 810%;
    font-weight: 500!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -18%!important;
    margin-left: 8.8%!important;
  }
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -5rem;
    font-size: 220%;
  }
}
@media screen and (min-width: 1650px) and (max-width: 1800px){
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -4.5rem!important;
    font-size: 200%!important;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1800px){
  .creating{
    font-size: 710%;
    font-weight: 500!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -18%!important;
    margin-left: 8.8%!important;
  }
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -4.3rem;
    font-size: 182%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .creating{
    font-size: 610%;
    font-weight: 500!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -18%!important;
    margin-left: 8.8%!important;
  }
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -3.4rem;
    font-size: 146%;
  }
}
@media screen and (min-width: 1350px) and (max-width: 1500px) {
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -3.8rem;
    font-size: 162%;
  }
}
@media screen and (min-width: 850px) and (max-width: 991px) {
  .creating{
    font-size: 350%;
    font-weight: 500!important;
    line-height: 35px!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -16%!important;
    margin-left: 6%!important;
  }
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -1.3rem;
    font-size: 103%;
  }
}
@media screen and (min-width: 768px) and (max-width: 850px) {
  .creating{
    font-size: 350%;
    font-weight: 500!important;
    line-height: 35px!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -17%!important;
    margin-left: 8%!important;
  }
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -1.2rem;
    font-size: 93%;
  }
}
@media screen and (min-width: 700px) and (max-width: 767px) {
  .creatingd{
    margin-top: -17%!important;
  }
}
@media screen and (min-width: 640px) and (max-width: 767px) {
  .creating{
    font-size: 320%;
    font-weight: 500!important;
    line-height: 30px!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -20%!important;
    margin-left: 6%!important;
  }
  .creating1{
    right: 2%;
    color: white;
    position: absolute;
    margin-top: -1.3rem;
    font-size: 78%;
  }
}
@media screen and (min-width: 550px) and (max-width: 640px) {
  .creating{
    font-size: 260%;
    font-weight: 500!important;
    line-height: 23px!important;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -21%!important;
    margin-left: 7.5%!important;
  }
  .creating1{
    right: 3%;
    color: white;
    position: absolute;
    margin-top: -2.95rem;
    font-size: 10px;
  }
}
@media screen and (min-width: 440px) and (max-width: 460px) {
  .creating{
    font-size: 220%;
    font-weight: 640;
    line-height: 18px;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -21.5%!important;
    margin-left: 8%!important;
  }
}
@media screen and (min-width: 460px) and (max-width: 500px) {
  .creating{
    font-size: 220%;
    font-weight: 640;
    line-height: 18px;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -21.5%!important;
    margin-left: 4%!important;
  }
}
@media screen and (min-width: 500px) and (max-width: 550px) {
  .creating{
    font-size: 220%;
    font-weight: 640;
    line-height: 18px;
  }
  .creatingd{
    font-size: 9px!important;
    position: absolute;
    margin-top: -21.5%!important;
    margin-left: 6%!important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
  .footertext{
    font-size: 14.2px;
    font-family: "RobotoRegular";
    font-weight: 500;
}
}
@media screen and (min-width: 1400px)  {
  .footertext{
    font-size: 17px;
    font-family: "RobotoRegular";
    font-weight: 500;
}
}
.EOE-logo {
  margin-top: 1rem;
  width: 110px !important;
}
.react-multiple-carousel__arrow {
  z-index:100 !important
}

.aboutcontent{
  text-align: justify;
}
.failtext{
  margin-top: -2rem;
}
.creatingd{
  font-size: 39px;
  color: #04a8e1;
  position: absolute;
  margin-top: -16%;
  margin-left: 8.8%;
}
 #creating1{
   color: white!important;
   
 }
 @media only screen and (min-width: 335px) and (max-width: 340px){
  
  .banner{
    width: 395px!important;
  }
}
.creating1{
  font-weight: 700!important;
}