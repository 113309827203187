body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.start-0 {
  left: 0!important;
}
.bottom-0 {
  bottom: 0!important;
}
#cookieSettingModal {
  padding-top: 5rem;
}
#cookieSettingModal .form-switch .form-check-input {
  margin-top: -0.75rem;
  margin-left: -3rem;
}
#cookieSettingModal .modal-header .btn-close {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  border: 0;
  box-sizing: initial;
  height: 1em;
  width: 1em;
  opacity: 0.5;
  background-repeat: no-repeat;
}